(function () {
  let store = {emailText: null, error: null, loading: false};

  function updateStore(data) {
    for (key in data) {
      store[key] = data[key];
    }
    render();
  }

  const emailInput = document.querySelector('[name=email]');
  const downloadBtn = document.querySelector('button');
  const downloadBlock = document.querySelector('.download');
  const downloadErrorText = document.querySelector('.download_error');
  const downloadForm = document.querySelector('.download-form');

  emailInput.addEventListener('input', onTextChanged);
  emailInput.addEventListener('change', onTextChanged);
  downloadBtn.addEventListener('click', sendEmail);
  downloadForm.addEventListener('submit', onSubmit);

  function onTextChanged(ev) {
    // if (ev.keyCode === 13)
    //   return;
    updateStore({emailText: emailInput.value, error: null});
  }

  function onSubmit(ev) {
    ev.preventDefault();
    sendEmail();
  }

  function sendEmail() {
    updateStore({loading: true});
    const email = store.emailText || emailInput.value;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', '//api.epibook.doubletapp.ru/epibook/' + encodeURI(email), true);
    const basicAuth = btoa("admin:secret");
    xhr.setRequestHeader("Authorization", "Basic " + basicAuth);
    xhr.send();

    xhr.onreadystatechange = function () {
      if (xhr.readyState != 4) return;
      updateStore({loading: false});
      if (xhr.status != 200) {
        console.log(xhr.status + ': ' + xhr.statusText);
      } else {
        const response = JSON.parse(xhr.responseText);
        updateStore({error: response.error || null});
        if (response.url)
          startDownload(response.url);
      }
    }
  }

  function startDownload(url) {
    window.location = url;
  }

  function render() {
    downloadBtn.disabled = !store.emailText || store.loading;
    downloadBlock.classList.toggle('-has-error', store.error);
    downloadErrorText.textContent = store.error? "* " + store.error : '';
  }

  render();

})();